// Подлключаем шрифты
// +font-face(MuseoSans, light, /fonts/MuseoSansLight, 300, normal, false )
@include font-face(MuseoSans, regular, /fonts/MuseoSansRegular, 400, normal, false );
// +font-face(MuseoSans, medium, /fonts/MuseoSansMedium, 500, normal, false )
@include font-face(MuseoSans, bold, /fonts/MuseoSansBold, 600, normal, false );
@include font-face(MuseoSans, black, /fonts/MuseoSansBlack, 900, normal, false );

 :root {
	--white: #fff;
	--dark: #222F3F;
	--sea: #7BD3E1;
	--red: #FE1C37;
	--darkgradient: #265361 #222F3F #222F3F #265361; }

$red: #FE1C37;

$default-font: "MuseoSans", sans-serif;

html {}


body {
	font-size: 18px;
	line-height: 1.6;
	font-family: $default-font;
	// +css-lock(16, 50, 600, 1000)
	min-height: 100vh;
	background: var(--sea);
	color: #fff;
	@include r(425) {
		font-size: 16px; } }


.out {
	overflow: hidden; }


strong {
	font-weight: normal; }

h1, h2, h3, h4, h5, h6 {
	font-size: 1rem;
	font-weight: normal; }

.h1, .h2, .h3, .h4, .h5, .h6 {}

.icon {
	fill: currentColor; }

.link, .button {
	display: inline-block;
	&:hover {
		text-decoration: none; } }

.img-responsive {
	display: block;
	max-width: 100%; }

.link {
	&-white {
		color: #fff;
		&:hover {
			color: #fff; } } }

.button {
	padding: 20px 30px;
	background: var(--red);
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 600;
	border-radius: 3px;
	text-align: center;
	transition: all .25s ease-out;
	@include r(425) {
		font-size: 14px;
		padding: 20px 5px; }
	&:hover {
		color: #fff;
		background: darken($red, 5%);
		transform: translateY(-5px);
		box-shadow:  0 5px 20px -10px rgba(#000, .25); }
	span {
		display: inline-block;
		margin: 0 5px; }
	.fa-chevron-right,.fa-chevron-left {
		letter-spacing: -3px;
		animation: 2s blink ease-out infinite;
		&:nth-child(1),
		&:nth-child(6) {
			opacity: .25; }
		&:nth-child(2),
		&:nth-child(5) {
			opacity: .5;
			animation-delay: .25s; }
		&:nth-child(3),
		&:nth-child(4) {
			opacity: .75;
			animation-delay: .5s; } }

	&-big {
		display: block; } }

@keyframes blink {
	0%, 100% {
		opacity: 0; }
	40% {
		opacity: 1; } }

